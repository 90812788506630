<template>
  <div>
    <img class="zdimg" src="../../assets/newimg/zidongimg.png" alt />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: []
    };
  },

  methods: {},
  created() {
  
  }
};
</script>

<style lang="less" scoped>
.zdimg {
  width: 100%;
}
</style>
